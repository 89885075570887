/* profile */
.profile {
    display: flex;
    width: auto;
    align-items: center;
    cursor: pointer;
}

.profile .avatar-profile {
    border-radius: 50%;
    overflow: hidden;
    width: 40px;
    height: 40px;
}

.profile .avatar-profile img {
    max-width: 100%;
    max-height: 100%;
}

.profile .user-profile {
	padding: 0px 10px;
	display: flex;
}

.profile .user-profile span {
    padding: 0px 10px;
}

/* profile menu box */

.profile-box {
	display: none;
    border-radius: .4em!important;
	background-color: #fff!important;
	width: 200px!important;
	border-radius: 5px!important;
	z-index: 100!important;
	position: absolute!important;
	margin-top: 60px!important;
	margin-left: -50px!important;
	text-align: left!important;
	-webkit-box-shadow: 0px 4px 21px -4px rgba(168, 166, 168, 0.7)!important;
	-moz-box-shadow: 0px 4px 21px -4px rgba(168, 166, 168, 0.7)!important;
	box-shadow: 0px 4px 21px -4px rgba(168, 166, 168, 0.7)!important;
	top: 0;
}

.profile-box:after {
	content: ''!important;
	position: absolute!important;
	top: 0!important;
	left: 70%!important;
	width: 0!important;
	height: 0!important;
	border: 10px solid transparent!important;
	border-bottom-color: #fff!important;
	border-top: 0!important;
	margin-left: -20px!important;
	margin-top: -10px!important;
}

.profile-menu {
	list-style-type: none!important;
    color: gray!important;
    padding: 0;
	margin: 0;
	padding-bottom: 10px
}

.profile-item {
    text-align: center;
    cursor: pointer;
}

.profile-item a {
	padding: 10px 5px;
	display: block;
}

.profile-item a:hover {
	background-color: #ebedf0;
}

.profile-item-text {
	padding: 10px 5px;
    text-align: center;
}

#app-login:hover > .profile-box {
	display: block;
}