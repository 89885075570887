/* override PrimeNG theme font family */
$fontFamily: "Trebuchet MS", Arial, Helvetica, sans-serif;
$fontFamilyAntenna: Antenna, Arial, Helvetica, sans-serif;
$fontFamilyAntennaLight: AntennaLight, Arial, Helvetica, sans-serif;
$fontFamilyAntennaCond: AntennaCond, Arial, Helvetica, sans-serif;

/*Font Properties*/
$FordAntennaLight : 300;
$FordAntennaRegular : 400;
$FordAntennaMedium : 500;
$FordAntennaBold : 700;
$font-face-style : normal;

/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/

$ford-blue : #003478;

$ford-blue-dark : #102b4e;

$white 	   : #fff;

/*ui component Colors*/
//Reference: http://chir.ag/projects/name-that-color/#003478

$Matisse : #215A8F;

$silver : #ccc;

$Perano : #9fcbf1;

$grey : #888;

$Lochmara : #0678bc;

$Chathams-blue : #124c85;

$Rhino : #27405f;

$Tundora : #444;

$seashell : #eee;

$MineShaft : #222;


$p-table-bg : $Matisse;

$p-sidebar-button: $ford-blue-dark;

$p-sidebar-bg : $silver;

$p-panel-bg : $Perano;

$p-menubar-border : $grey;

$p-menuitem-link-color : $Lochmara;

$p-menuitems-text : $seashell;

$p-menubar-box-shadow-color : $MineShaft;  

$sidebar-app-login-bg : $Chathams-blue;

$btn-sidebar-bg : $Rhino;

$sidebar-app-login-color : $Tundora;   

$app-footer-bg : $seashell;


/*Responsive*/
$app-header-transition : font-size .5s ease-in;