@import "variables";


.p-menubar {
	background: $p-table-bg;
}
.p-state-active {
	background: #428bca;
}

.p-menubar .p-menuitem .p-menuitem-link { /* First Level items */
	color: $white;
	background: none !important;
	border-radius: 0.5rem;
}
.p-menubar .p-menuitem .p-menuitem-link:hover, .p-menubar .p-menubar-custom .sidebar-app-login a:hover{
	background-color: $sidebar-app-login-bg !important;
}


.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:hover{
	background-color: $sidebar-app-login-bg !important;
}  


.p-menubar .p-menuitem .p-menuitem-link:active, .p-menubar .p-menubar-custom .sidebar-app-login a:active{
	background-color: $sidebar-app-login-bg !important;
	box-shadow: inset 0 0 7px $sidebar-app-login-color;
}
.p-menubar .p-menuitem .p-menuitem-link.p-state-active{
	background-color: $btn-sidebar-bg !important; //required due to PrimeNG scripted style
	box-shadow: inset 0 0 7px $p-menubar-box-shadow-color;
	transition: background-color .5s ease-in;
}
.p-menubar .p-menuitem.p-menuitem-active, .p-menubar > .p-menuitem.p-menuitem-active .p-menuitem-link{ /* First Level items (Active) */
	background: $sidebar-app-login-bg;
}
.p-menubar .p-submenu-list{
	background: $white;
}
.p-menubar .p-submenu-list .p-menuitem .p-menuitem-link { /* Second Level items */
	color: $p-menuitem-link-color;
	background: $white;
	box-shadow: inset 0 0 0 $sidebar-app-login-color;
}
.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem-link { /* Second Level items (Active) */
	background: $app-footer-bg !important;
	color: $p-menuitem-link-color;
}

.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem .p-menuitem-link{
	background: $white;
}
.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem.p-menuitem-active .p-menuitem-link{
	background: $app-footer-bg;
}

.p-menubar .p-menu-parent .p-menu-child{
	background: $white;
}
.p-sidebar{
	border: 0;
}
.p-sidebar .p-menubar .p-menuitem .p-menuitem-link{
	border-bottom: 1px solid $p-menubar-border;
}

.p-sidebar .p-menubar .p-submenu-list .p-menuitem .p-menuitem-link{
	border-bottom: 1px solid $p-sidebar-bg;
}
.p-sidebar-close{
	color: $ford-blue;
	background: $p-sidebar-bg;
}

.btn-sidebar button{
	background: $p-sidebar-bg;
	border: 0;
	outline: none;
	font-size: 1.5em;
	color: $white;
	border-radius: 20%;
}

.btn-sidebar button:active{
	box-shadow: inset 0 0 15px $white;
}

.p-menubar .p-menubar-custom .sidebar-app-login a{
	color: $white !important; //required due to PrimeNG scripted style
	background: none;
	border-radius: 0;
	border-bottom: 1px solid $p-menubar-border;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
	color: $white !important;
}


.p-menubar .p-menubar-custom .sidebar-app-login a:hover, .p-menubar .p-menubar-custom .sidebar-app-login a:active, .p-menubar .p-menubar-custom .sidebar-app-login a:focus{
	color: $white;
}
.p-menubar .p-menubar-custom .sidebar-app-login .fa{
	color: $white;
}

/* fix bug on add pButton dependency*/
.mobile-menu .p-button-text.p-clickable {
	display: none;
}