/* primeng doesn't provide much form styling. add back in */
.p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext{
	width: 100%;
}
.p-autocomplete-token{
	margin: .125em;
}
.p-autocomplete .p-autocomplete-input{
	height: 34px;
}
form{
	border-radius: 5px;
	padding: .5em;
	box-shadow: 0 1px 10px #ccc;
}
input[type="text"], input[type="number"] {
	border: 1px solid #ccc;
	border-radius: 3px;
	padding: 5px;
	height: 34px;
}