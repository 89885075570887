p-listbox,
.p-listbox {
    width: 100%;
}

.p-listbox .p-listbox-list .p-listbox-item {
    margin: 0;
    padding: 0.3rem 1rem;
    border: 0 none;
    color: #495057;
    transition: box-shadow 0.2s;
    border-radius: 0.5rem;
}