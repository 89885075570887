fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}
.form-actions {
	padding: 1em;
	margin: 0 -.3125em .3125em -.3125em;
}
/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	width: 100%;
}
.p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext{
	width: 100%;
}
.p-autocomplete-token{
	margin: .125em;
}
input[type="text"], input[type="number"] {
	height: 34px;
}

form .p-dropdown{
	width: 100% !important;
	height: 34px;
	padding: .125em;
}
form .p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext{
	width: 100%;
}

.p-calendar input{
	width: 80%;
}