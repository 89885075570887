dl, h5 {
	margin: 0;
	padding: 0;
}
dd {
	margin-left: 0;
	margin-top: .5rem;
	word-wrap: break-word;
}

.p-panel-footer {
	text-align: center !important;
}