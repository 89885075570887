/* REMINDER - css properties that affect style such as color, font-family go in the theme scss folder */
/* css properties for layout are the properties which affect positioning and those go in the layout css folder */
/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
.p-datatable th.p-state-default.p-state-active {
  background: #0d73b0;
}

.p-datatable th.p-state-default {
  background: #215A8F;
  color: #fff;
}

.p-datatable th.p-sortable-column:not(.p-state-active):hover {
  background: #215A8F;
  color: #fff;
}

.p-steps .p-steps-title {
  font-family: AntennaCond, Arial, Helvetica, sans-serif;
  font-size: 0.9rem;
}

.p-widget, label, legend, option {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.p-steps .p-steps-item .p-steps-number {
  background-color: #0081c2;
  color: #FFFFFF;
  border-radius: 50%;
}

.p-steps .p-steps-item .p-steps-title {
  color: #555555;
}

.p-panel .p-widget-header, .p-panel-header {
  background: linear-gradient(to bottom, #9fcbf1 0%, #cee5f9 100%) !important;
  border-color: #9fcbf1;
}

.p-widget-content {
  border: 1px solid #abd8ec;
}

.p-panel-footer {
  background-color: #e3f2ff;
} /*
.p-panel-footer .p-button, .p-panel-footer .p-button:hover {
	color: #337ab7 !important;
	background: none !important;
	border: 0 !important;
}
.p-panel-footer .p-button span, .p-panel-footer .p-button:hover span {
	text-decoration: underline;
}*/
.p-autocomplete-token {
  font-size: 100%;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */
@font-face { /* Ford Antenna Bold */
  font-family: Antenna;
  src: url("../../../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face { /* Ford Antenna Medium */
  font-family: Antenna;
  src: url("../../../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face { /* Ford Antenna Regular */
  font-family: Antenna;
  src: url("../../../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
@font-face { /* Ford Antenna Light */
  font-family: AntennaLight;
  src: url("../../../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}
@font-face { /* Ford Antenna Light Italic */
  font-family: AntennaLight;
  src: url("../../../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}
@font-face { /* Ford Antenna Condensed Bold */
  font-family: AntennaCond;
  src: url("../../../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}
@font-face { /* Ford Antenna Condensed Medium */
  font-family: AntennaCond;
  src: url("../../../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
}
@font-face { /* Ford Antenna Condensed Regular */
  font-family: AntennaCond;
  src: url("../../../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"), url("../../../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}
/********************************* [END] Ford Fonts *********************************/
/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
#app-header #app-branding h1 {
  font-weight: 200;
  font-size: 30px;
}

#app-header #app-branding #app-icon {
  color: white;
  font-size: 13px;
}

#app-footer > div {
  background-image: linear-gradient(to bottom, #eee 0, #fff 100%);
}

body {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
@media (min-width: 50px) and (max-width: 479px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.75rem;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 480px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.25rem; /*20px; this is what it used to be before converting to rems*/
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.375rem;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.5rem;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 1024px) and (max-width: 1286px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.625rem;
    transition: font-size 0.5s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding #app-name h1 {
    font-size: 1.75rem;
    transition: font-size 0.5s ease-in;
  }
}
/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
.p-menubar {
  background: #215A8F;
}

.p-state-active {
  background: #428bca;
}

.p-menubar .p-menuitem .p-menuitem-link { /* First Level items */
  color: #fff;
  background: none !important;
  border-radius: 0.5rem;
}

.p-menubar .p-menuitem .p-menuitem-link:hover, .p-menubar .p-menubar-custom .sidebar-app-login a:hover {
  background-color: #124c85 !important;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text:hover {
  background-color: #124c85 !important;
}

.p-menubar .p-menuitem .p-menuitem-link:active, .p-menubar .p-menubar-custom .sidebar-app-login a:active {
  background-color: #124c85 !important;
  box-shadow: inset 0 0 7px #444;
}

.p-menubar .p-menuitem .p-menuitem-link.p-state-active {
  background-color: #27405f !important;
  box-shadow: inset 0 0 7px #222;
  transition: background-color 0.5s ease-in;
}

.p-menubar .p-menuitem.p-menuitem-active, .p-menubar > .p-menuitem.p-menuitem-active .p-menuitem-link { /* First Level items (Active) */
  background: #124c85;
}

.p-menubar .p-submenu-list {
  background: #fff;
}

.p-menubar .p-submenu-list .p-menuitem .p-menuitem-link { /* Second Level items */
  color: #0678bc;
  background: #fff;
  box-shadow: inset 0 0 0 #444;
}

.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem-link { /* Second Level items (Active) */
  background: #eee !important;
  color: #0678bc;
}

.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem .p-menuitem-link {
  background: #fff;
}

.p-menubar .p-submenu-list .p-menuitem.p-menuitem-active .p-menuitem.p-menuitem-active .p-menuitem-link {
  background: #eee;
}

.p-menubar .p-menu-parent .p-menu-child {
  background: #fff;
}

.p-sidebar {
  border: 0;
}

.p-sidebar .p-menubar .p-menuitem .p-menuitem-link {
  border-bottom: 1px solid #888;
}

.p-sidebar .p-menubar .p-submenu-list .p-menuitem .p-menuitem-link {
  border-bottom: 1px solid #ccc;
}

.p-sidebar-close {
  color: #003478;
  background: #ccc;
}

.btn-sidebar button {
  background: #ccc;
  border: 0;
  outline: none;
  font-size: 1.5em;
  color: #fff;
  border-radius: 20%;
}

.btn-sidebar button:active {
  box-shadow: inset 0 0 15px #fff;
}

.p-menubar .p-menubar-custom .sidebar-app-login a {
  color: #fff !important;
  background: none;
  border-radius: 0;
  border-bottom: 1px solid #888;
}

.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-link .p-menuitem-text {
  color: #fff !important;
}

.p-menubar .p-menubar-custom .sidebar-app-login a:hover, .p-menubar .p-menubar-custom .sidebar-app-login a:active, .p-menubar .p-menubar-custom .sidebar-app-login a:focus {
  color: #fff;
}

.p-menubar .p-menubar-custom .sidebar-app-login .fa {
  color: #fff;
}

/* fix bug on add pButton dependency*/
.mobile-menu .p-button-text.p-clickable {
  display: none;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
  font-family: AntennaCond, Arial, Helvetica, sans-serif;
}

h1, .h1 {
  font-weight: normal;
  font-size: 2.4rem;
}

h2, .h2 {
  font-size: 1.5rem;
}

h3, .h3 {
  color: #777;
  font-size: 1.18rem;
}

h4, .h4 {
  color: #444;
  font-size: 1rem;
}

h5, .h5 {
  color: #666;
}

h5, h6, .h5, .h6 {
  font-family: Antenna, Arial, Helvetica, sans-serif;
}

a, a:focus {
  color: #0678bc;
  text-decoration: none;
}

a:hover {
  color: #27405f;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

#app-footer {
  background-image: linear-gradient(to bottom, #eee 0, #fff 100%);
}

#app-branding h1 {
  font-family: Antenna, Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: #036;
}

#app-branding h1 a {
  text-decoration: none;
}
#app-branding h1 a:hover, #app-branding h1 a:focus {
  cursor: pointer;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
.form-actions {
  border-top: 1px solid #e5e5e5;
}

label, legend {
  font-weight: 700;
  font-size: 1rem;
}

fieldset label {
  font-weight: 400;
}

textarea {
  font-family: inherit;
}

/* primeng doesn't provide much form styling. add back in */
input[type=text], input[type=number], textarea, select {
  padding: 0.3125em;
  font-size: 1em;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}

.p-widget input, .p-widget select, .p-widget textarea, .p-widget button {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  font-size: 1em;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
dt {
  font-weight: 700;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
.p-button-link {
  background: none !important;
  border: none;
  outline: none;
  cursor: pointer;
  color: #0678bc;
  text-decoration: none;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  line-height: 1.5;
  font-size: 16px;
}

.p-button-link:hover {
  color: #27405f;
}

/* profile */
.profile {
  display: flex;
  width: auto;
  align-items: center;
  cursor: pointer;
}

.profile .avatar-profile {
  border-radius: 50%;
  overflow: hidden;
  width: 40px;
  height: 40px;
}

.profile .avatar-profile img {
  max-width: 100%;
  max-height: 100%;
}

.profile .user-profile {
  padding: 0px 10px;
  display: flex;
}

.profile .user-profile span {
  padding: 0px 10px;
}

/* profile menu box */
.profile-box {
  display: none;
  border-radius: 0.4em !important;
  background-color: #fff !important;
  width: 200px !important;
  border-radius: 5px !important;
  z-index: 100 !important;
  position: absolute !important;
  margin-top: 60px !important;
  margin-left: -50px !important;
  text-align: left !important;
  -webkit-box-shadow: 0px 4px 21px -4px rgba(168, 166, 168, 0.7) !important;
  -moz-box-shadow: 0px 4px 21px -4px rgba(168, 166, 168, 0.7) !important;
  box-shadow: 0px 4px 21px -4px rgba(168, 166, 168, 0.7) !important;
  top: 0;
}

.profile-box:after {
  content: "" !important;
  position: absolute !important;
  top: 0 !important;
  left: 70% !important;
  width: 0 !important;
  height: 0 !important;
  border: 10px solid transparent !important;
  border-bottom-color: #fff !important;
  border-top: 0 !important;
  margin-left: -20px !important;
  margin-top: -10px !important;
}

.profile-menu {
  list-style-type: none !important;
  color: gray !important;
  padding: 0;
  margin: 0;
  padding-bottom: 10px;
}

.profile-item {
  text-align: center;
  cursor: pointer;
}

.profile-item a {
  padding: 10px 5px;
  display: block;
}

.profile-item a:hover {
  background-color: #ebedf0;
}

.profile-item-text {
  padding: 10px 5px;
  text-align: center;
}

#app-login:hover > .profile-box {
  display: block;
}

p-listbox,
.p-listbox {
  width: 100%;
}

.p-listbox .p-listbox-list .p-listbox-item {
  margin: 0;
  padding: 0.3rem 1rem;
  border: 0 none;
  color: #495057;
  transition: box-shadow 0.2s;
  border-radius: 0.5rem;
}