.nowrap {
  white-space: nowrap;
} /* would like to have this in the crupdate component but the shadow dom emulation doesn't quite work with PrimeNG's rendering */
.p-steps.steps-crupdate-agent .p-steps-item {
  width: 33.333333%;
}

.p-steps.booking-crupdate-steps .p-steps-item {
  width: 16.666666666%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.vertical-center {
  bottom: 0;
  top: 0;
  margin: auto;
}

/* primeng doesn't provide much form styling. add back in */
.p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext {
  width: 100%;
}

.p-autocomplete-token {
  margin: 0.125em;
}

.p-autocomplete .p-autocomplete-input {
  height: 34px;
}

form {
  border-radius: 5px;
  padding: 0.5em;
  box-shadow: 0 1px 10px #ccc;
}

input[type=text], input[type=number] {
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  height: 34px;
}

dl, h5 {
  margin: 0;
  padding: 0;
}

dd {
  margin-left: 0;
  margin-top: 0.5rem;
  word-wrap: break-word;
}

.p-panel-footer {
  text-align: center !important;
}

.p-button-link {
  margin: 0 0.25em;
}

button, .p-button {
  margin: 0.125em;
}

.p-dropdown {
  width: auto !important; /*This is used in /booking-list (Results Per Page - dropdown) required due to PrimeNG scripted style*/
}

/* Override for the form 100% widths */
.p-g-12 .p-dropdown {
  width: 100% !important;
}

label[class^=p-g] {
  padding-bottom: 0;
}

textarea {
  width: 99%;
}

.p-autocomplete-input-token, .p-autocomplete-input-token input {
  width: 100%;
  border: none;
}

.p-calendar .p-calendar-button {
  margin: 0 0.125px 0 0;
}

.p-calendar {
  width: 100%;
}

.p-calendar .p-inputtext {
  width: 100%;
}