/* REMINDER - css properties that affect style such as color, font-family go in the theme scss folder */
/* css properties for layout are the properties which affect positioning and those go in the layout css folder */

@import "datatable";
@import "fonts";
@import "header";
@import "responsive";
@import "sidebar";
@import "type";
@import "variables";
@import "form";
@import "panels";
@import "buttons";
@import "profile";
@import "listbox";
