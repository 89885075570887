.p-menubar {
	border: none;
	padding: 0;
	border-radius: 0.5rem;
}
.p-menubar .p-menubar-root-list{
	margin: 0;
}
.p-menubar .p-menuitem .p-menuitem-link { /* First Level items */
	text-decoration: none;
	padding: 1em !important;
}
.p-menubar .p-submenu-list{
	min-width: 15em;
}
.p-menubar .p-submenu-list .p-menuitem .p-menuitem-link { /* Second Level items */
	padding: .5em !important;
	margin: 0;
}
.p-menubar .p-menu-parent .p-menu-child{
	background: #fff;
}
.p-sidebar{
	padding: 0;
	overflow-y: auto;
}
.p-sidebar .p-menubar .p-menubar-root-list, .p-sidebar .p-menubar .p-menubar-root-list>.p-menuitem{
	display: block;
	width: 100%;
}

.p-sidebar .p-menubar .p-submenu-list, .p-sidebar .p-menubar .p-menu-parent .p-menu-child{
	position: static;
	width: 100%;
}
.p-sidebar-close{
	z-index: 10000;
	position: absolute;
	right: 0;
	padding: .125em .25em;
}

.btn-sidebar button{
	padding: .25em .5em;
}
.p-menubar .p-menubar-custom{
	float: none;
	margin: 0;
	padding: 0;
}
.p-menubar .p-menubar-custom .sidebar-app-login a{
	display: block;
	width: 100%;
	padding: .75em;
}
