:root {
  font-size: 16px;
}

/* REMINDER - css properties for layout are the properties which affect positioning and those go in the layout css folder */
/* css properties that affect style such as color, font-family go in the theme scss folder */
/* primeng doesn't have layout helpers. add them here */
.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.items-center {
  display: flex;
  align-items: center;
}

/* override PrimeNG theme font family */
/*Font Properties*/
/* Color Palette
-------------------------------------------------- */
/*Constant Colors*/
/*ui component Colors*/
/*Responsive*/
/* primeng tables are fixed cell widths. gross. override back to default */
.p-datatable table {
  table-layout: auto;
}

.p-datatable table th,
.p-datatable table td {
  width: auto;
}

.p-steps {
  margin: 0.5em 0;
}

.p-steps .p-steps-title {
  white-space: normal;
}

.p-steps .p-steps-item .p-menuitem-link {
  height: auto;
  padding: 0 1em;
}

.p-steps .p-steps-item.p-state-highlight, .p-steps .p-steps-item.p-state-default {
  border: none;
  background: none;
}

.p-steps .p-steps-item:before {
  display: block;
  content: "";
  border: 0.3125em solid #0678bc;
  position: relative;
  top: 1em;
}

.p-steps .p-steps-item .p-steps-number {
  display: inline-block;
  width: 36px;
  margin-top: -0.875em;
  margin-bottom: 0.625em;
}

.p-panel.p-widget {
  margin-top: 1.25em;
}

.p-table table {
  table-layout: auto;
}

.p-panel-footer {
  padding: 0 1em;
}

/*.p-panel-footer button, .p-panel-footer span {
	padding: .5em;
}*/
.no-content .p-panel-content, .no-header .p-panel-header {
  display: none;
}

#app-header #app-branding h1 {
  margin: 0;
  line-height: 1.5em;
  display: inline-block;
}

#app-header #app-branding {
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1em 0;
}

#app-header #app-branding #app-icon {
  align-self: center;
  text-align: center;
}

#app-header #app-branding #app-name {
  flex-grow: 1;
  padding: 0 1em;
  align-self: center;
}

#app-header #app-branding #app-login {
  float: right;
  text-align: right;
  align-self: center;
}

#app-header #app-branding #app-icon img {
  display: inline-block;
}

#app-header #app-branding #app-login a {
  margin: 0 0.5em;
}

#app-header #app-branding #app-login a .fa {
  padding-right: 0.25em;
}

#app-footer > div {
  width: 100%;
}

#app-branding #app-login {
  display: block;
}

.sidebar-app-login, .btn-sidebar {
  display: none;
}

html, body, app-root {
  max-width: 1600px;
  margin: auto;
}

app-root {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: stretch;
  align-content: stretch;
}

app-header {
  flex-shrink: 0;
  flex-basis: auto;
  margin: 0 0.5em 1em;
}

@media (min-width: 50px) and (max-width: 767px) {
  .p-sidebar .p-menubar, .sidebar-app-login, .btn-sidebar {
    display: block;
  }
  .quicklinks-list li a {
    padding: 0.15em;
  }
  #app-footer * {
    text-align: center;
  }
  #app-branding .logo {
    max-width: 90px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 50px) and (max-width: 479px) {
  #app-header #app-branding #app-name {
    text-align: center;
  }
  #app-header #app-branding .logo {
    max-width: 95px;
    transition: max-width 0.5s ease-in;
  }
  #app-header #app-branding #app-icon {
    align-self: flex-start;
    padding-top: 0.3em;
  }
}
@media (min-width: 480px) {
  #app-header #app-branding .logo {
    max-width: 100px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 768px) {
  #app-header #app-branding .logo {
    max-width: 105px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 960px) {
  #app-header #app-branding .logo {
    max-width: 110px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 1024px) and (max-width: 1286px) {
  #app-header #app-branding .logo {
    max-width: 115px;
    transition: max-width 0.5s ease-in;
  }
}
@media (min-width: 1287px) {
  #app-header #app-branding .logo {
    max-width: 120px;
    transition: max-width 0.5s ease-in;
  }
}
.p-menubar {
  border: none;
  padding: 0;
  border-radius: 0.5rem;
}

.p-menubar .p-menubar-root-list {
  margin: 0;
}

.p-menubar .p-menuitem .p-menuitem-link { /* First Level items */
  text-decoration: none;
  padding: 1em !important;
}

.p-menubar .p-submenu-list {
  min-width: 15em;
}

.p-menubar .p-submenu-list .p-menuitem .p-menuitem-link { /* Second Level items */
  padding: 0.5em !important;
  margin: 0;
}

.p-menubar .p-menu-parent .p-menu-child {
  background: #fff;
}

.p-sidebar {
  padding: 0;
  overflow-y: auto;
}

.p-sidebar .p-menubar .p-menubar-root-list, .p-sidebar .p-menubar .p-menubar-root-list > .p-menuitem {
  display: block;
  width: 100%;
}

.p-sidebar .p-menubar .p-submenu-list, .p-sidebar .p-menubar .p-menu-parent .p-menu-child {
  position: static;
  width: 100%;
}

.p-sidebar-close {
  z-index: 10000;
  position: absolute;
  right: 0;
  padding: 0.125em 0.25em;
}

.btn-sidebar button {
  padding: 0.25em 0.5em;
}

.p-menubar .p-menubar-custom {
  float: none;
  margin: 0;
  padding: 0;
}

.p-menubar .p-menubar-custom .sidebar-app-login a {
  display: block;
  width: 100%;
  padding: 0.75em;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin: 0 0 0.5em;
}

h1, .h1, h2, .h2 {
  margin-bottom: 0.41666em;
}

p {
  margin: 0 0 0.625em;
}

hr {
  margin-bottom: 1em;
}

#app-content {
  height: auto;
  flex-grow: 2;
  margin: 0 1.5em 1em;
}

#app-footer {
  flex-shrink: 0;
  flex-basis: auto;
  width: 100%;
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
}

.form-actions {
  padding: 1em;
  margin: 0 -0.3125em 0.3125em -0.3125em;
}

/* primeng doesn't provide much form styling. add back in */
input[type=text], input[type=number], textarea, select {
  width: 100%;
}

.p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext {
  width: 100%;
}

.p-autocomplete-token {
  margin: 0.125em;
}

input[type=text], input[type=number] {
  height: 34px;
}

form .p-dropdown {
  width: 100% !important;
  height: 34px;
  padding: 0.125em;
}

form .p-autocomplete, .p-autocomplete .p-autocomplete-multiple-container.p-inputtext {
  width: 100%;
}

.p-calendar input {
  width: 80%;
}

.export-btn-group {
  float: right;
}

.export-btn-group > button {
  margin: 0.3125em 0 0.3125em 0.3125em;
}

.p-button-link {
  padding: 0 !important;
}

table .p-widget-content td:first-child {
  overflow: visible;
}