@import "../theme/variables";

/* primeng tables are fixed cell widths. gross. override back to default */
.p-datatable table {
	table-layout: auto;
}
.p-datatable table th,
.p-datatable table td {
	width: auto;
}

.p-steps {
	margin: .5em 0;
}
.p-steps .p-steps-title {
	white-space: normal;
}
.p-steps .p-steps-item .p-menuitem-link{
	height: auto;
	padding: 0 1em;
}
.p-steps .p-steps-item.p-state-highlight, .p-steps .p-steps-item.p-state-default{
	border: none;
	background: none;
}
.p-steps .p-steps-item:before{
	display: block;
	content: '';
	border: .3125em solid #0678bc;
	position: relative;
	top:1em;
}
.p-steps .p-steps-item .p-steps-number {
	display: inline-block;
	width: 36px;
	margin-top: -.875em;
	margin-bottom: .625em;
}

.p-panel.p-widget {
	margin-top: 1.25em;
}
.p-table table {
	table-layout: auto;
}
.p-panel-footer {
	padding: 0 1em;
}
/*.p-panel-footer button, .p-panel-footer span {
	padding: .5em;
}*/
.no-content .p-panel-content, .no-header .p-panel-header {
	display: none;
}

