@import "variables";


h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
	margin: 0 0 .5em;
}
h1, .h1, h2, .h2 {
	margin-bottom:.41666em;
}
p{
	margin: 0 0 .625em;
}
hr{
	margin-bottom: 1em;
}

#app-content{
	height: auto;
	flex-grow: 2;
	margin: 0 1.5em 1em;
}
#app-footer{
	flex-shrink: 0;
	flex-basis: auto;
	width: 100%;
}
