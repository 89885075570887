:root {
	font-size: 16px;
}
/* REMINDER - css properties for layout are the properties which affect positioning and those go in the layout css folder */
/* css properties that affect style such as color, font-family go in the theme scss folder */

@import "content";
@import "datatable";
@import "header";
@import "responsive";
@import "sidebar";
@import "type";
@import "variables";
@import "form";
@import "panels";
@import "buttons";
@import "tables";