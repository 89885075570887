

/* would like to have this in the crupdate component but the shadow dom emulation doesn't quite work with PrimeNG's rendering */
.p-steps.steps-crupdate-agent .p-steps-item {
	width: 33.333333%;
}

.p-steps.booking-crupdate-steps .p-steps-item {
	width: 16.666666666%;
}

