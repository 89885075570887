.p-dropdown {
	width: auto !important; /*This is used in /booking-list (Results Per Page - dropdown) required due to PrimeNG scripted style*/
}

/* Override for the form 100% widths */
.p-g-12 .p-dropdown {
	width: 100% !important;
}

label[class^='p-g']{
	padding-bottom: 0;
}
textarea{
	width: 99%;
}

.p-autocomplete-input-token, .p-autocomplete-input-token input{
	width: 100%;
	border: none;
}
.p-calendar .p-calendar-button{
	margin: 0 .125px 0 0;
}