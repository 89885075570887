@import "variables";


@media (min-width: 50px) and (max-width: 479px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.75rem; //18px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 480px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.25rem; /*20px; this is what it used to be before converting to rems*/
		transition: $app-header-transition;
	}
}

@media (min-width: 768px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.375rem; //22px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 960px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.5rem; //24px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 1024px) and (max-width: 1286px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.625rem; //26px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}

@media (min-width: 1287px) {
	#app-header #app-branding #app-name h1 {
		font-size: 1.75rem; //28px; this is what it used to be before converting to rems
		transition: $app-header-transition;
	}
}