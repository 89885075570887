
.form-actions {
	border-top: 1px solid #e5e5e5;
}

label, legend {
	font-weight: 700;
	font-size: 1rem;
}

fieldset label {
	font-weight: 400;
}

textarea {
	font-family: inherit;
}
/* primeng doesn't provide much form styling. add back in */
input[type="text"], input[type="number"], textarea, select {
	padding: .3125em;
	font-size: 1em;
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
}
.p-widget input, .p-widget select, .p-widget textarea, .p-widget button {
	font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
	font-size: 1em;
}