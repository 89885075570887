@import "variables";


h1, h2, h3, h4,
.h1, .h2, .h3, .h4 {
	font-family: $fontFamilyAntennaCond;
}
h1, .h1 {
	font-weight: normal;
	font-size: 2.4rem;
}
h2, .h2 {
	font-size: 1.5rem;
}
h3, .h3 {
	color: #777;
	font-size: 1.18rem;
}
h4, .h4 {
	color: $sidebar-app-login-color;
	font-size: 1rem;
}
h5, .h5 {
	color: #666;
}
h5, h6, .h5, .h6 {
	font-family: $fontFamilyAntenna;
}

a, a:focus{
	color: $p-menuitem-link-color;
	text-decoration: none;
}
a:hover{
	color: $btn-sidebar-bg;
}
hr{
	border: 0;
	border-top: 1px solid $seashell;
}

#app-footer{
	background-image: linear-gradient(to bottom,$app-footer-bg 0,$white 100%);
}
#app-branding h1 {
	font-family: $fontFamilyAntenna;
	font-weight: 700;
	font-size: 1.125rem;
	color: #036;
}

#app-branding h1 a {
	text-decoration: none;
	&:hover,
	&:focus {
		cursor: pointer;
	}
}