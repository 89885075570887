@import "variables";

/********************************* [START] Ford Fonts *********************************/
/* This CSS resource incorporates links to font software which is the valuable copyrighted property of WebType LLC, The Font Bureau, Ascender Corporation and/or their suppliers. You may not attempt to copy, install, redistribute, convert, modify or reverse engineer this font software. Please contact WebType with any questions: http://www.webtype.com */

@font-face {	/* Ford Antenna Bold */
	font-family: Antenna;
	src: url("../../../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Bold/3fcdf9c8-1bf9-49f4-b9e9-48fba95ab07b-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaBold;
}

@font-face {	/* Ford Antenna Medium */
	font-family: Antenna;
	src: url("../../../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Medium/16cfc826-eb8a-47b2-8d9b-928e47f8157e-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaMedium;
}
@font-face {	/* Ford Antenna Regular */
	font-family: Antenna;
	src: url("../../../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Regular/d9e5c1ae-3c12-46d7-97a6-a7ff0861e239-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaRegular;
}
@font-face {	/* Ford Antenna Light */
	font-family: AntennaLight;
	src: url("../../../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaLight;
}
@font-face {	/* Ford Antenna Light Italic */
	font-family: AntennaLight;
	src: url("../../../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853-1.ttf") format("truetype");
	font-style: italic;
	font-weight: $FordAntennaLight;
}
@font-face {	/* Ford Antenna Condensed Bold */
	font-family: AntennaCond;
	src: url("../../../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaBold;
}
@font-face {	/* Ford Antenna Condensed Medium */
	font-family: AntennaCond;
	src: url("../../../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaMedium;
}
@font-face {	/* Ford Antenna Condensed Regular */
	font-family: AntennaCond;
	src: url("../../../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-3.woff") format("woff"),
	url("../../../fonts/Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd-1.ttf") format("truetype");
	font-style: $font-face-style;
	font-weight: $FordAntennaRegular;
}
/********************************* [END] Ford Fonts *********************************/